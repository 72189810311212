<template>
    <div class="CourseMessage plate-center">
        <div class="search_head flex align_center">
            <div class="search_head_name">当前位置：</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item class="active">课程详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="CourseMessage_head flex">
            <div class="CourseMessage_head_img">
                <img :src="courseMessage.image" draggable="false" alt />
            </div>
            <div class="CourseMessage_head_main">
                <div class="CourseMessage_head_name">{{courseMessage.course_name}}</div>
                <ul class="CourseMessage_head_list flex">
                    <li>
                        <span>学习人数：</span>
                        <span>120学时</span>
                    </li>
                    <li>
                        <span>学时：</span>
                        <span>{{courseMessage.hours}}</span>
                    </li>
                    <li>
                        <span>培训等级：</span>
                        <span>{{courseMessage.tag}}</span>
                    </li>
                    <li>
                        <span>课程讲师：</span>
                        <span>{{courseMessage.teacher_name}}</span>
                    </li>
                </ul>
                <div class="CourseMessage_head_time flex align_center flex_between">
                    <div class="CourseMessage_head_time_l">
                        <span>报名时间：</span>
                        <span>{{courseMessage.time}}</span>
                    </div>
                    <div
                        v-if="courseMessage.can_sign_up == 1 || (courseMessage.is_paid == 0 && courseMessage.sign_status == 2)"
                        class="CourseMessage_head_time_btn flex align_center flex_center"
                        @click="applySubmit({course_id: course_id})"
                    >立即报名</div>
                    <div
                        v-if="courseMessage.is_paid == 0 && courseMessage.can_sign_up == 0  && courseMessage.sign_status == 0"
                        class="CourseMessage_head_time_btn flex align_center flex_center"
                    >审核中</div>
                    <div
                        v-if="(courseMessage.is_paid && courseMessage.is_paid == 1) || (courseMessage.can_sign_up == 0 && courseMessage.is_paid == 0 && courseMessage.sign_status == 1)"
                        class="CourseMessage_head_time_btn flex align_center flex_center"
                        @click="pageJumps('courseDetails',{id: course_id})"
                    >继续学习</div>
                </div>
            </div>
        </div>
        <div class="CourseMessage_main">
            <ul class="CourseMessage_main_head flex">
                <li
                    v-for="(item,index) in 2"
                    :key="index"
                    :class="{active:index == i}"
                    @click="i = index"
                >
                    <span>{{index == 0 ? '课程目录' : '简介'}}</span>
                </li>
            </ul>
            <ul class="CourseMessage_main_list" v-if="i == 0">
                <li
                    v-for="(item,index) in courseMessage.chapter"
                    :key="index"
                    class="flex align_center"
                >{{item.name}}</li>
            </ul>
            <div class="CourseMessage_main_txt" v-else>
                <div class v-html="courseMessage.introduction"></div>
            </div>
        </div>
        <apply ref="apply"></apply>
    </div>
</template>

<script>
import apply from "@/components/apply";
export default {
    name: "CourseMessage",
    components: {
        apply,
    },
    data() {
        return {
            i: 0,
            course_id: null,
            courseMessage: {}, //
        };
    },
    methods: {
        //获取课程详情
        getCourseMessage() {
            this.$axios
                .post("/api/index/courseDetail", { course_id: this.course_id })
                .then((res) => {
                    if (res.code === 1) {
                        this.courseMessage = res.data;
                    }
                });
        },
        // 立即报名
        applySubmit(item) {
            if (this.judgeIsLogin()) {
                this.$refs.apply.show(item);
            }
        },
    },
    created() {
        this.course_id = this.$route.query.id;
        this.getCourseMessage();
    },
};
</script>

<style scoped lang="scss">
.CourseMessage {
    .search_head {
        padding: 15px 0;
        .search_head_name {
            font-size: 16px;
            color: #333;
        }

        .active {
            /deep/ .el-breadcrumb__inner {
                color: #1263ff !important;
            }
        }
    }
    .CourseMessage_head {
        padding: 30px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        .CourseMessage_head_img {
            width: 370px;
            height: 277px;
            img {
                border-radius: 3px;
            }
            margin-right: 40px;
        }
        .CourseMessage_head_main {
            flex: 1;
            padding-top: 50px;
            .CourseMessage_head_name {
                color: #333333;
                font-size: 24px;
                font-weight: bold;
                line-height: 21px;
                margin-bottom: 110px;
            }
            .CourseMessage_head_list {
                flex-wrap: wrap;
                width: 520px;
                li {
                    width: 260px;
                    margin-bottom: 18px;
                    span {
                        font-size: 16px;
                        &:last-child {
                            color: #1263ff;
                        }
                    }
                }
            }
            .CourseMessage_head_time {
                width: 100%;
                .CourseMessage_head_time_l {
                    span {
                        font-size: 16px;
                        &:last-child {
                            color: #ff9c00;
                        }
                    }
                }
                .CourseMessage_head_time_btn {
                    width: 160px;
                    height: 48px;
                    background: #1263ff;
                    border-radius: 24px;
                    color: #ffffff;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }
    .CourseMessage_main {
        margin: 20px 0 60px 0;
        border: 1px solid #f2f2f2;
        border-top: none;
        .CourseMessage_main_head {
            background: #fafafa;
            li {
                height: 78px;
                flex: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    color: #333333;
                    font-size: 20px;
                }
            }
            .active {
                span {
                    color: #1263ff;
                    font-weight: bold;
                    position: relative;
                    &::after {
                        width: 77px;
                        height: 2px;
                        content: "";
                        bottom: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                        position: absolute;
                        background: #1263ff;
                        border-radius: 1px;
                    }
                }
            }
        }
        .CourseMessage_main_list {
            li {
                height: 76px;
                padding-left: 40px;
                font-size: 16px;
                border-bottom: 1px solid #efefef;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .CourseMessage_main_txt {
            padding: 30px;
        }
    }
}
</style>
