<template>
    <div class="CourseMessage plate-center">
        <div class="search_head flex align_center">
            <div class="search_head_name">当前位置：</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item class="active">课程详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="CourseMessage_head flex">
            <div class="CourseMessage_head_img">
                <img :src="info.image" alt />
            </div>
            <div class="CourseMessage_head_main">
                <div class="CourseMessage_head_name">{{info.name || ''}}</div>
                <ul class="CourseMessage_head_list flex">
                    <li>
                        <span>培训专业：</span>
                        <span>{{info.cname || ''}}</span>
                    </li>
                    <li>
                        <span>所属机构：</span>
                        <span>{{info.organ || ''}}</span>
                    </li>
                    <li>
                        <span>学习形式：</span>
                        <span>{{info.learning_type || ''}}</span>
                    </li>
                    <li>
                        <span>班级状态：</span>
                        <span>{{info.status || ''}}</span>
                    </li>
                    <li>
                        <span>培训等级：</span>
                        <span>{{info.level || ''}}</span>
                    </li>
                    <li>
                        <span>班主任：</span>
                        <span>{{info.headmaster || ''}}</span>
                    </li>
                    <li>
                        <span>联系电话：</span>
                        <span>{{info.headmaster_tel || ''}}</span>
                    </li>
                </ul>
                <div class="CourseMessage_head_time flex align_center flex_between">
                    <div class="CourseMessage_head_time_l">
                        <span>报名时间：</span>
                        <span>{{info.time || ''}}</span>
                    </div>
                    <div
                        v-if="info.can_sign_up == 1 || (info.is_paid == 0 && info.sign_status == 2)"
                        class="CourseMessage_head_time_btn flex align_center flex_center"
                        @click="applySubmit({course_id: info.course_id})"
                    >立即报名</div>
                    <div
                        v-if="info.is_paid == 0 && info.can_sign_up == 0  && info.sign_status == 0"
                        class="CourseMessage_head_time_btn flex align_center flex_center"
                        @click="globalUseModuleFn('warm','show')"
                    >立即报名</div>
                    <div
                        v-if="(info.is_paid && info.is_paid == 1) || (info.can_sign_up == 0 && info.is_paid == 0 && info.sign_status == 1)"
                        class="CourseMessage_head_time_btn flex align_center flex_center"
                        @click="pageJumps('courseDetails',{id: info.course_id})"
                    >继续学习</div>
                </div>
            </div>
        </div>
        <apply ref="apply"></apply>
        <warm ref="warm"></warm>
    </div>
</template>

<script>
import apply from "@/components/apply";
import warm from "@/components/Warm";
export default {
    name: "CustomizationMessage",
    components: {
        apply,
        warm,
    },
    data() {
        return {
            id: "",
            info: {},
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.getDetail();
    },
    methods: {
        // 获取定制课程分类
        getDetail() {
            let api = "";
            if (this.judgeIsLogin(false) && this.userInfo.real_name_auth == 1) {
                api = this.$api.ddzcourseDetail;
            } else {
                api = this.$api.dzcourseDetail;
            }
            this.$axios.post(api, { id: this.id }).then((res) => {
                if (res.code === 1) {
                    this.info = res.data;
                }
            });
        },
        // 立即报名
        applySubmit(item) {
            if (this.judgeIsLogin()) {
                if (this.userInfo.real_name_auth == 0) {
                    this.$store.commit("changeNoodeRenzheng", true);
                } else {
                    this.$refs.apply.show(item);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.CourseMessage {
    .search_head {
        padding: 15px 0;
        .search_head_name {
            font-size: 16px;
            color: #333;
        }

        .active {
            /deep/ .el-breadcrumb__inner {
                color: #1263ff !important;
            }
        }
    }
    .CourseMessage_head {
        padding: 30px;
        margin-bottom: 120px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        .CourseMessage_head_img {
            width: 370px;
            height: 277px;
            img {
                border-radius: 3px;
            }
            margin-right: 40px;
        }
        .CourseMessage_head_main {
            flex: 1;
            padding-top: 20px;
            .CourseMessage_head_name {
                color: #333333;
                font-size: 24px;
                font-weight: bold;
                line-height: 21px;
                margin-bottom: 30px;
            }
            .CourseMessage_head_list {
                flex-wrap: wrap;
                width: 520px;
                li {
                    width: 260px;
                    margin-bottom: 18px;
                    span {
                        font-size: 16px;
                        &:last-child {
                            color: #1263ff;
                        }
                    }
                }
            }
            .CourseMessage_head_time {
                width: 100%;
                .CourseMessage_head_time_l {
                    span {
                        font-size: 16px;
                        &:last-child {
                            color: #ff9c00;
                        }
                    }
                }
                .CourseMessage_head_time_btn {
                    width: 160px;
                    height: 48px;
                    background: #1263ff;
                    border-radius: 24px;
                    color: #ffffff;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
