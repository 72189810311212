<template>
    <div class="mycourses yh-w100 yh-pd20">
        <ul>
            <li class="level_align yh-fc333 yh-mb20" v-for="(item, index) in list" :key="index">
                <img class="item-img yh-mr20" :src="item.image" alt />
                <div class="flex-1 flex flex_column">
                    <h2 class="yh-fz24">{{ item.course_name }}</h2>
                    <div class="level_align flex_between">
                        <div class="yh-lh30 yh-mt10 yh-mr40 yh-w100">
                            <div class="level_align">
                                <span>学习期限：</span>
                                <span>{{item.start_time}} —— {{ item.end_time }}</span>
                            </div>
                            <div class="level_align">
                                <span>学习进度：</span>
                                <div class="flex-1">
                                    <el-progress
                                        class="yh-w100"
                                        :show-text="true"
                                        :text-inside="true"
                                        :stroke-width="16"
                                        :percentage="item.task ||0"
                                    ></el-progress>
                                </div>
                            </div>
                            <div class="level_align">
                                <p class="flex-1">
                                    <span>学习目标：</span>
                                    <span>{{ item.hours }}</span>
                                </p>
                                <p class="flex-1">
                                    <span>已获学时：</span>
                                    <span>{{item.finished_hour}}</span>
                                </p>
                            </div>
                        </div>
                        <el-button
                            v-if="item.is_paid == 1"
                            type="primary"
                            round
                            @click="pageJumps('courseDetails',{'id':item.course_id},true)"
                        >继续学习</el-button>
                        <el-button v-if="item.is_paid == 0" type="info" plain disabled round>审核中</el-button>
                    </div>
                </div>
            </li>
        </ul>
        <!-- 分页器 S-->
        <el-pagination
            class="center_combo yh-h88"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="prev, pager, next"
            :total="page.total"
        ></el-pagination>
        <!-- 分页器 E-->
    </div>
</template>

<script>
export default {
    // components: {
    //   Modal
    // },
    data() {
        return {
            page: {
                per_page: 10, // 每页数量
                page: 1, // 页码
                total: null, // 总页数
            },
            list: [], // 列表
        };
    },
    created() {
        this.getList();
    },
    methods: {
        handleSizeChange(val) {
            this.page.per_page = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.page.page = val;
            this.getList();
        },
        // 获取我的课程列表
        getList() {
            this.$axios
                .post(this.$api.myCourseList, {
                    per_page: this.page.per_page,
                    page: this.page.page,
                })
                .then((res) => {
                    this.showLoadMore = false;
                    if (res.code === 1) {
                        this.page.total = res.data.total;
                        this.list = res.data.data;
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.item-img {
    width: 240px;
    height: 180px;
}
</style>
