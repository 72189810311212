<template>
    <div class="notescontacts yh-w100">
        <div class="real_title level_align flex_between">
            <span class="yh-fc-blue">{{title}}</span>
            <div class="yh-ptb0lr30">
                <el-button
                    class="yh-mr20"
                    type="text"
                    icon="el-icon-refresh-left"
                    @click="golobalBack"
                    >返回</el-button
                >
            </div>
        </div>

        <div class="yh-pd20">
            <div
                class="yh-bcF5 yh-mb20 yh-br4 yh-pd20 yh-lh30 yh-fc333 yh-bsf5 yh-fz16 yh-bbox yh-cp"
                v-for="(item, index) in list"
                :key="index"
            >
                <div class="level_align">
                    <div class="level_align">
                        <span class="yh-fc666 yh-mr20">视频名称</span>
                        {{ item.title }}
                    </div>
                </div>
                <div class="level_align yh-fc999">
                    <div class="level_align flex-1">
                        <span class="yh-fc666 yh-mr20">视频总时长</span>
                        <span class="yh-mr20 yh-w140">
                            {{ item.duration || 0 }}</span
                        >
                        <span class="yh-fc666 yh-mr20">已学时长</span>
                        {{ item.uhours || 0 }}
                    </div>
                    <div class="level_align flex_end">
                        <el-button
                            type="warning"
                            size="small"
                            round
                            @click="
                                pageJumps('viewPhotos', {
                                    name: '查看照片',
                                    id: item.id,
                                })
                            "
                            >查看照片</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            round
                            @click="
                                pageJumps('viewPhotos', {
                                    name: '查看笔记',
                                    id: item.id,
                                })
                            "
                            >查看笔记</el-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: "",
			title: '',
            list: [],
        };
    },
    created() {
        this.backtop();
		this.id = this.$route.query.id
		this.title = this.$route.query.name
    },
	mounted(){
		this.getMyRecord()
	},
    methods: {
        // 获取获取我的档案 章节详情
        getMyRecord() {
            this.$axios
                .post(this.$api.recordChapter, { id: this.id })
                .then((res) => {
                    if (res.code == 1) {
                        if (res.data.length) {
                            res.data.map((item) => {
                                item.duration = this.s_to_hs(item.duration);
                                item.uhours = this.s_to_hs(item.uhours);
                            });
                        }
                        this.list = res.data;
                    }
                });
        },
        s_to_hs(s) {
            //计算分钟
            //算法：将秒数除以60，然后下舍入，既得到分钟数
            var m, h;
            m = Math.floor(s / 60);
            //计算秒
            //算法：取得秒%60的余数，既得到秒数
            s = s % 60;

            // 算法： 将分钟除以60，然后下舍入，既得到小时
            h = Math.floor(m / 60);

            //将变量转换为字符串
            h += "";
            m += "";
            s += "";

            // 如果只有一位数，前面增加一个0
            h = h.length == 1 ? "0" + h : h;
            m = m.length == 1 ? "0" + m : m;
            s = s.length == 1 ? "0" + s : s;
            return h + " : " + m + " : " + s;
        },
    },
};
</script>

<style lang="scss" scoped>
.real_title {
    background: #f8f8f8;
    span {
        padding: 10px 0 10px 20px;
        display: inline-block;
        border-left: 5px solid #1678ff;
        font-size: 18px;
    }
}
</style>
