<template>
    <div class="message yh-w100">
        <div class="real_title">
            <span>客服列表</span>
        </div>

        <p class="yh-fc666 yh-ptb0lr30 yh-lh56">客服电话</p>
        <div class="yh-ptb0lr30 yh-lh30 level_align" v-for="(item,index) in list" :key="index">
            <span class="yh-mr20">{{item.name}}</span>
            <span class="yh-mr20">电话：{{item.mobile}}</span>
            <span class="yh-mr20">微信： {{item.wx}}</span>
        </div>
    </div>
</template>

<script>
// import { messageList } from "@/config/api";
export default {
    data() {
        return {
            list: [],
        };
    },
    created() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            this.$axios.post("/api/common/customer").then((res) => {
                if (res.code == 1) {
                    this.list = res.data;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.real_title {
    background: #f8f8f8;
    span {
        padding: 10px 0 10px 20px;
        display: inline-block;
        border-left: 5px solid #1678ff;
        font-size: 18px;
    }
}
</style>
