<template>
    <div class="realname yh-w100">
        <div class="real_title">
            <span>实名认证</span>
        </div>
        <div class="identity yh-pd20">
            <p class="yh-fc999 yh-lh64">身份证</p>
            <div class="level_align">
                <div
                    class="zheng-img yh-pd20 yh-bcF5 yh-br8 yh-mr20"
                    v-if="userInfo.real_name_auth == 0"
                >
                    <img class="yh-cp" src="../../assets/image/p-shenfenzheng-zheng.png" alt />
                </div>
                <div
                    class="zheng-img yh-pd20 yh-bcF5 yh-br8 yh-mr20"
                    v-if="userInfo.real_name_auth == 1"
                >
                    <img class="yh-cp" :src="userInfo.idcard_front" alt />
                </div>
                <!-- 身份证反面 -->
                <div class>
                    <div
                        class="zheng-img yh-pd20 yh-bcF5 yh-br8 yh-mr20"
                        v-if="userInfo.real_name_auth == 0"
                    >
                        <img class="yh-cp" src="../../assets/image/shenfenzheng-fan.png" alt />
                    </div>
                    <div
                        class="zheng-img yh-pd20 yh-bcF5 yh-br8 yh-mr20"
                        v-if="userInfo.real_name_auth == 1"
                    >
                        <img class="yh-cp" :src="userInfo.idcard_back" alt />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    mounted() {
        if (this.userInfo.real_name_auth == 0) {
            this.$store.commit("changeNoodeRenzheng", true);
        }
    },
};
</script>

<style lang="scss" scoped>
.real_title {
    background: #f8f8f8;
    span {
        padding: 10px 0 10px 20px;
        display: inline-block;
        border-left: 5px solid #1678ff;
        font-size: 18px;
    }
}
.zheng-img {
    width: 295px;
    height: 187px;
}
</style>
