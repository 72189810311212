<template>
    <div class="search">
        <div class="search_nav">
            <ul class="search_nav_list">
                <li>
                    <div class="search_nav_list_name">工种类别:</div>
                    <ul class="search_nav_list_ul flex align_center">
                        <li
                            v-for="(item, index) in dzcourseCategory"
                            :key="index"
                            :class="{'yh-fc-blue': actCategory.id == item.id}"
                            @click="changeCtegory(item)"
                        >{{item.name}}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="search_main">
            <ul class="home_course_main_list">
                <li>
                    <ul class="home_course_main_ul flex align_center">
                        <li>工种</li>
                        <li>等级</li>
                        <li>班级名称</li>
                        <li>区域</li>
                        <li>学习时间</li>
                        <li>学习形式</li>
                        <li>报名条件</li>
                        <li>报名入口</li>
                    </ul>
                </li>
                <li>
                    <ul
                        class="home_course_main_ul flex align_center"
                        v-for="(item,index) in list"
                        :key="index"
                    >
                        <li>{{item.cname}}</li>
                        <li>{{item.level}}</li>
                        <li class="yh-fc" style=" width: 100px;">{{item.name}}</li>
                        <li>{{item.city}}{{item.area}}</li>
                        <li>{{item.time}}</li>
                        <li>{{item.learning_type}}</li>
                        <li @click="hint = true" class="yh-cp" style="color: #1263FF">查看报名条件</li>
                        <li>
                            <div
                                v-if="item.can_sign_up == 1 || (item.is_paid == 0 && item.sign_status == 2)"
                                class="btn flex align_center flex_center"
                                @click="applySubmit(item)"
                            >立即报名</div>
                            <div
                                v-if="item.is_paid == 0 && item.can_sign_up == 0  && item.sign_status == 0"
                                class="btn flex align_center flex_center"
                                @click="globalUseModuleFn('warm','show')"
                            >立即报名</div>
                            <div
                                v-if="(item.is_paid && item.is_paid == 1) || (item.can_sign_up == 0 && item.is_paid == 0 && item.sign_status == 1)"
                                class="btn flex align_center flex_center"
                                @click="pageJumps('courseDetails',{id: item.course_id})"
                            >继续学习</div>
                        </li>
                    </ul>
                </li>
            </ul>
            <Error class="yh-mb20" v-if="!haveList" />
            <div class="search_page flex align_center flex_center">
                <el-pagination
                    class="center_combo yh-h88"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="prev, pager, next"
                    :total="page.total"
                ></el-pagination>
            </div>
        </div>
        <div class="search_open flex align_center flex_center" v-show="hint">
            <span>提示</span>
            <span>年满十八周岁即可</span>
        </div>
        <apply ref="apply"></apply>

        <warm ref="warm"></warm>
    </div>
</template>

<script>
import warm from "@/components/Warm";
import apply from "@/components/apply";
export default {
    name: "Customization",
    components: {
        apply,
        warm,
    },
    filters: {},
    data() {
        return {
            hint: false, // 提示
            dzcourseCategory: [], // 分类
            actCategory: {}, // 选中的分类
            list: [],
            page: {
                per_page: 10, // 每页数量
                page: 1, // 页码
                total: null, // 总页数
            },
            haveList: true, // 有列表数据
        };
    },
    created() {
        this.getDZcourseCategory();
    },
    watch: {
        hint(e) {
            if (e) {
                setTimeout(() => {
                    this.hint = false;
                }, 1000);
            }
        },
    },
    methods: {
        // 获取定制课程分类
        getDZcourseCategory() {
            this.$axios.post(this.$api.courseCategoryList).then((res) => {
                if (res.code === 1) {
                    let obj = {
                        id: -1,
                        name: "全部",
                    };
                    this.dzcourseCategory = res.data.data;
                    this.dzcourseCategory.unshift(obj);
                    if (res.data.data.length) {
                        this.actCategory = res.data.data[0];
                        this.getDZcourseList();
                    }
                }
            });
        },
        // 获取定制课程列表
        getDZcourseList() {
            let api = "";
            if (this.judgeIsLogin(false) && this.userInfo.real_name_auth == 1) {
                api = this.$api.ddzcourseList;
            } else {
                api = this.$api.dzcourseList;
            }
            this.$axios
                .post(api, {
                    cid: this.actCategory.id,
                    per_page: this.page.per_page,
                    page: this.page.page,
                })
                .then((res) => {
                    if (res.code === 1) {
                        this.list = res.data.data;
                        this.page.total = res.data.total;
                        if (this.list.length) {
                            this.haveList = true;
                        } else {
                            this.haveList = false;
                        }
                    }
                });
        },
        // 切换分类
        changeCtegory(item) {
            this.actCategory = item;
            this.getDZcourseList();
        },
        handleSizeChange(val) {
            this.page.per_page = val;
            this.getDZcourseList();
        },
        handleCurrentChange(val) {
            this.page.page = val;
            this.getDZcourseList();
        },
        // 立即报名
        applySubmit(item) {
            if (this.judgeIsLogin()) {
                if (this.userInfo.real_name_auth == 0) {
                    this.$store.commit("changeNoodeRenzheng", true);
                } else {
                    this.$refs.apply.show(item);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
    .search_nav {
        padding-top: 20px;
        margin-bottom: 30px;
        .search_nav_list {
            width: 1200px;
            background: #ffffff;
            border: 1px solid #f2f2f2;
            li {
                display: flex;
                padding: 30px 40px 20px 10px;
                border-bottom: 1px dashed #efefef;
                &:last-child {
                    border-bottom: none;
                }
                .search_nav_list_name {
                    color: #999999;
                    font-size: 14px;
                    flex-shrink: 0;
                    margin-right: 20px;
                }
                .search_nav_list_ul {
                    flex-wrap: wrap;
                    li {
                        padding: 0;
                        font-size: 14px;
                        cursor: pointer;
                        margin-right: 20px;
                        margin-bottom: 10px;
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .search_main {
        .home_course_main_list {
            background: #ffffff;
            margin-bottom: 60px;
            border: 1px solid #f2f2f2;
            li {
                padding: 0 20px 0 30px;
                border-bottom: 1px solid #efefef;
                &:last-child {
                    border-bottom: none;
                }
                .home_course_main_ul {
                    display: flex;
                    justify-content: space-between;
                    li {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        width: 90px;
                        border-bottom: none;
                        justify-content: center;
                        &:nth-child(4) {
                            width: 120px;
                        }
                        &:nth-child(5) {
                            width: 190px;
                        }
                        color: #333333;
                        font-size: 14px;
                        height: 73px;
                        .btn {
                            cursor: pointer;
                            color: #ffffff;
                            font-size: 12px;
                            width: 76px;
                            height: 29px;
                            background: #1263ff;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
    .search_error {
        margin-top: 178px;
    }
    .search_open {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 280px;
        width: 360px;
        height: 215px;
        flex-direction: column;
        background: rgba(51, 51, 51, 0.9);
        border-radius: 10px;
        span {
            color: #ffffff;
            font-size: 24px;
            &:first-child {
                margin-bottom: 60px;
            }
        }
    }
}
</style>
