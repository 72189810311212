<template>
  <div class="setup yh-w100 center_combo">
    <img class="kaifa-img" src="../../assets/image/kaifaz.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.setup{
  height: 800px;
}
.kaifa-img{
  width: 303px;
  height: 208px;
}
</style>
