<template>
    <div class="Intangible">
        <div class="intangible_nav">
            <ul class="intangible_nav_list">
                <li>
                    <div class="intangible_nav_list_name">分类:</div>
                    <ul class="intangible_nav_list_ul flex">
                        <li
                            @click="changeClassify(0) "
                            :class="{'yh-fc-blue': generalBranch == 0}"
                        >预见非遗</li>
                        <li
                            @click="changeClassify(1)"
                            :class="{'yh-fc-blue': generalBranch == 1}"
                        >工匠精神</li>
                    </ul>
                </li>
                <li>
                    <div class="intangible_nav_list_name">所属分类:</div>
                    <ul class="intangible_nav_list_ul flex" v-if="generalBranch == 0">
                        <li
                            v-for="(item, index) in categories"
                            :key="index"
                            @click="categoriesup(item)"
                            :class="categoriesActive.cid == item.cid ? 'categoriesActive':''"
                        >{{ item.name }}</li>
                    </ul>
                    <ul class="intangible_nav_list_ul flex" v-if="generalBranch == 1">
                        <li
                            v-for="(item, index) in gongjiangCategories"
                            :key="index"
                            @click="gongjiangCategoriesup(item)"
                            :class="gongjiangCategoriesActive.cid == item.cid ? 'categoriesActive':''"
                        >{{ item.name }}</li>
                    </ul>
                </li>
                <!-- <li>
                    <div class="intangible_nav_list_name">等级:</div>
                    <ul class="intangible_nav_list_ul flex">
                        <li v-for="(item, index) in 5" :key="index">国家级</li>
                    </ul>
                </li>-->
            </ul>
        </div>

        <div class="intangible_main">
            <ul class="home_intangible_main_list" v-if="generalBranch == 0">
                <li
                    v-for="(item, index) in pageList"
                    :key="index"
                    @click="pageJumps('IntanDetails',{id: item.id})"
                >
                    <div class="home_intangible_main_list_img">
                        <img :src="item.image" alt />
                    </div>
                    <div class="home_intangible_main_list_txt">
                        <div class="home_intangible_main_list_name">{{item.name}}</div>
                    </div>
                </li>
            </ul>
            <ul class="home_intangible_main_list" v-if="generalBranch == 1">
                <li
                    v-for="(item, index) in pageListGongjiang"
                    :key="index"
                    @click="pageJumps('artisan',{id: item.id})"
                >
                    <div class="home_intangible_main_list_img">
                        <img :src="item.image" alt />
                    </div>
                    <div class="home_intangible_main_list_txt">
                        <div class="home_intangible_main_list_name">{{item.name}}</div>
                    </div>
                </li>
            </ul>
            <Error class="yh-mb20" v-if="!haveList" :name="generalBranch |pageName " />
            <div class="intangible_page flex align_center flex_center" v-if="categories.length != 0 || gongjiangCategories.length != 0">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.page"
                    layout="prev, pager, next"
                    :total="page.total"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Intangible",
    filters: {
        pageName(e) {
            if (e == 0) {
                return "非遗";
            } else if (e == 1) {
                return "工匠精神";
            }
        },
    },
    data() {
        return {
            generalBranch: 0, // 总分类 0 非遗	1 工匠
            categories: [], // 非遗分类
            gongjiangCategories: [], // 工匠分类
            categoriesActive: {}, // 选中的非遗
            gongjiangCategoriesActive: {}, // 选中的工匠
            page: {
                per_page: 10, // 每页数量
                page: 1, // 页码
                total: null, // 总页数
            },
            pageList: [], // 非遗列表
            pageListGongjiang: [], // 工匠列表
            haveList: true, // 有列表数据
        };
    },
    methods: {
        // 切换大分类
        changeClassify(e) {
            this.generalBranch = e;
            this.page.page = 1;
            this.hint();
            if (e == 0) {
              this.culturalList()
            } else {
              this.gongjiangList()
            }
        },
        // 没有数据 提示
        hint() {
            if (this.generalBranch == 0 && !this.pageList.length) {
                this.haveList = false;
            } else if (
                this.generalBranch == 1 &&
                !this.pageListGongjiang.length
            ) {
                this.haveList = false;
            } else {
                this.haveList = true;
            }
        },
        /* 请求非遗接口 */
        foresee() {
            this.$axios.post("/api/index/culturalCategory").then((res) => {
                if (res.code == 1) {
                    this.categories = res.data;
                    if (this.categories.length) {
                        this.categoriesup(this.categories[0]);
                    }
                }
            });
            this.$axios.post("/api/index/artisanCategory").then((res) => {
                if (res.code == 1) {
                    this.gongjiangCategories = res.data;
                    if (this.gongjiangCategories.length) {
                        this.gongjiangCategoriesup(this.gongjiangCategories[0]);
                    }
                }
            });
        },
        // 通过类别ID获取非遗列表
        culturalList() {
            this.$axios
                .post("/api/index/culturalList", {
                    cid: this.categoriesActive.cid,
                    per_page: this.page.per_page,
                    page: this.page.page,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.page.total = res.data.total;
                        this.pageList = res.data.data;
                        this.hint();
                    }
                });
        },
        // 通过类别ID获取工匠列表
        gongjiangList() {
            this.$axios
                .post("/api/index/artisanList", {
                    cid: this.gongjiangCategoriesActive.cid,
                    per_page: this.page.per_page,
                    page: this.page.page,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.page.total = res.data.total;
                        this.pageListGongjiang = res.data.data;
                        this.hint();
                    }
                });
        },
        /* 非遗列表切换所属分类 */
        categoriesup(item) {
            this.categoriesActive = item;
            this.culturalList();
        },
        /* 工匠列表切换所属分类 */
        gongjiangCategoriesup(item) {
            this.gongjiangCategoriesActive = item;
            this.gongjiangList();
        },
        /* 列表跳转详情 */
        intanDetails() {
            this.$router.push(`/IntanDetails`);
        },
        handleSizeChange(val) {
            this.page.per_page = val;
            if (this.generalBranch == 0) {
                this.culturalList();
            } else if (this.generalBranch == 1) {
                this.gongjiangList();
            }
        },
        handleCurrentChange(val) {
            this.page.page = val;
            if (this.generalBranch == 0) {
                this.culturalList();
            } else if (this.generalBranch == 1) {
                this.gongjiangList();
            }
        },
    },
    mounted() {
        this.hint();
    },
    created() {
        this.generalBranch =
            (this.$route.query.generalBranch &&
                this.$route.query.generalBranch) ||
            0;
        this.foresee();
    },
};
</script>

<style scoped lang="scss">
.Intangible {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
    .intangible_nav {
        padding-top: 20px;
        margin-bottom: 10px;
        .intangible_nav_list {
            width: 1200px;
            background: #ffffff;
            //   border: 1px solid #f2f2f2;
            li {
                display: flex;
                padding: 30px 40px 20px 10px;
                border-bottom: 1px dashed #efefef;
                &:last-child {
                    border-bottom: none;
                }
                .intangible_nav_list_name {
                    color: #999999;
                    font-size: 14px;
                    flex-shrink: 0;
                    margin-right: 20px;
                }
                .intangible_nav_list_ul {
                    flex-wrap: wrap;
                    li {
                        padding: 0;
                        cursor: pointer;
                        margin-right: 20px;
                        margin-bottom: 10px;
                        border-bottom: none;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .intangible_main {
        .home_intangible_main_list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 90px;
            li {
                width: 227px;
                cursor: pointer;
                background: #ffffff;
                box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 0.3);
                border-radius: 4px;
                margin-right: 15px;
                margin-top: 20px;
                &:nth-child(5n) {
                    margin-right: 0;
                }
                .home_intangible_main_list_img {
                    width: 226px;
                    height: 170px;
                    img {
                        border-radius: 4px 4px 0px 0px;
                    }
                }
                .home_intangible_main_list_txt {
                    padding: 20px 10px;
                    .home_intangible_main_list_name {
                        font-size: 14px;
                        overflow: hidden;
                        width: 200px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #333333;
                    }
                }
            }
        }
    }
    .categoriesActive {
        color: #1263ff;
    }
}
</style>
