<template>
    <div class="notescontacts yh-w100">
        <div class="real_title level_align flex_between">
            <span class="yh-fc-blue">{{ pageName }}</span>
            <div class="yh-ptb0lr30">
                <el-button
                    class="yh-mr20"
                    type="text"
                    icon="el-icon-refresh-left"
                    @click="golobalBack"
                >返回</el-button>
            </div>
        </div>

        <div class="yh-pd20" v-if="pageName === '查看照片'">
            <el-image
                class="item-img"
                :style="{ marginRight: (index + 1) % 6 === 0 ? '0' : '14px' }"
                v-for="(item, index) in list"
                :key="index"
                :src="item"
                :preview-src-list="list"
            ></el-image>
        </div>
        <div class="yh-pd20 level_align flex_wrap" v-if="pageName === '查看笔记'">
            <el-upload
                class="avatar-uploader yh-mb15"
                style="margin-right: 14px"
                action="https://hnqkx.zzwangxiao.com/api/upload/upload_one_image"
                :headers="{
                    token: token,
                }"
                accept="image"
                name="image"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
            >
                <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div
                class="biji-item-img yh-br8 yh-mb15 yh-pr"
                v-for="(item, index) in noteList"
                :key="index"
                :style="{
                    marginRight: ((index + 1) % 6) - 5 === 0 ? '0' : '14px',
                }"
            >
                <el-image
                    class
                    style="width: 130px; height: 130px"
                    :src="item.img_path"
                    :preview-src-list="list"
                ></el-image>
                <div class="meng yh-fz22 yh-fcF">
                    <i class="el-icon-zoom-in yh-cp yh-mr10" @click="datu(item)"></i>
                    <i @click="Del(index)" class="el-icon-delete yh-cp"></i>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "viewPhotos",
    data() {
        return {
            pageName: "",
            id: "",
            list: [], // 图片列表
            noteList: [], // 笔记
            ids: [],
            dialogImageUrl: "",
            dialogVisible: false,
        };
    },
    created() {
        this.pageName = this.$route.query.name;
        this.id = this.$route.query.id;
        this.token = sessionStorage.getItem("token");
    },
    mounted() {
        if (this.pageName == "查看照片") {
            // 0 查看照片抓拍 2 查看笔记
            this.getRecordPhoto();
        } else {
            this.getRecordNote();
        }
    },
    methods: {
        // 查看大图
        datu(item) {
            this.dialogImageUrl = item.img_path;
            this.dialogVisible = true;
        },
        // 删除
        Del(index) {
            this.ids.splice(index, 1);
            this.AddNote();
        },
        // 上传成功
        handleAvatarSuccess(event) {
            this.ids.push(event.data.file_id);
            // this.noteList.push(URL.createObjectURL(file.raw));
            this.AddNote();
        },
        // 添加笔记
        AddNote() {
            let param = {
                file: this.ids.join(","),
                id: this.id,
            };
            this.$axios.post(this.$api.recordAddNote, param).then((res) => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.getRecordNote();
                }
            });
        },
        // 获取获取我的档案 抓拍照片
        getRecordPhoto() {
            this.$axios
                .post(this.$api.recordPhoto, { id: this.id })
                .then((res) => {
                    if (res.code == 1) {
                        this.list = res.data;
                    }
                });
        },
        // 获取获取我的档案 笔记
        getRecordNote() {
            this.$axios
                .post(this.$api.recordNote, { id: this.id })
                .then((res) => {
                    if (res.code == 1) {
                        let arr = [];
                        // brr = [];

                        if (res.data.length) {
                            res.data.forEach((item) => {
                                arr.push(item.img_id);
                                // brr.push(item.img_path);
                            });
                            this.ids = arr;
                            this.noteList = res.data;
                            // this.list = brr;
                        } else {
                            this.noteList = [];
                        }

                        this.$forceUpdate();
                    } else {
                        this.noteList = [];
                    }
                });
        },
    },
};
</script>

<style scoped lang="scss">
.real_title {
    background: #f8f8f8;
    span {
        padding: 10px 0 10px 20px;
        display: inline-block;
        border-left: 5px solid #1678ff;
        font-size: 18px;
    }
}
.item-img {
    width: 130px;
    height: 130px;
    margin-right: 14px;
}
.biji-item-img {
    width: 130px;
    height: 130px;
    margin-right: 14px;
    &:hover {
        .meng {
            display: block;
        }
    }
    .meng {
        width: 130px;
        height: 130px;
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        top: 0px;
        display: none;
        background: rgba(0, 0, 0, 0.6);
        line-height: 130px;
        text-align: center;
    }
}
.avatar-uploader {
    width: 130px;
    height: 130px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
}
</style>
